/**
* ./composables/useApiFetch.ts
 *
 * Wrapper around $apiFetch calls. It handles error and success messages and displays `toast` accordingly
 *
 */

export const useApiFetch = async (url: string, options:object={}, silent:boolean=false) => {
    const { $apiFetch, $formatValidationErrors } = useNuxtApp();
    const config = useRuntimeConfig().public.nuxtSanctumAuth;
    const toasts = useToastsStore();

    const data = ref(null)
    const error = ref(null)

    await $apiFetch(url, options).then((response: any) => {
        if (response && response.message) {
            if(! silent) {
                toasts.success(response.message);
            }
        }
        data.value = response;
    }).catch(async (e) => {
        let statusCode = e.status;
        if (statusCode === 422) {
            if(! silent) {
                toasts.validationError($formatValidationErrors(e.data.errors));
            }
            return;
        }

        if(! silent) {
            let message = e.data?.message ?? 'Something went wrong. Please try again later.';
            toasts.error(message);
        }

        if (statusCode === 403 || statusCode === 419) {
            await navigateTo(config.redirects.login);

            return;
        }

        error.value = e;
    });

    return {
        data,
        error
    }
};
